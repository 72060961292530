.BrainhubCarousel {
  position: relative;
  overflow: inherit;

  .BrainhubCarousel__trackContainer {
    .BrainhubCarouselItem {
      margin: 0 5px;
      width: 25%;

      > img {
        height: 400px;
        object-fit: cover;
      }
    }
  }
}
