.map-window {
  width: 200px;
  height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.map-title {
  color: #000000;
  font-family: 'neue-haas-unica';
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
}

.map-address {
  height: 32px;
  color: #747a7e;
  font-family: 'neue-haas-unica';
  font-size: 14px;
  letter-spacing: 0;
}
